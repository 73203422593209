import { twMerge } from "tailwind-merge";

const _logos = [
  <img src="/images/logos/outfund.svg" alt="Logo Outfund" />,
  <img src="/images/logos/fuel-ventures.svg" alt="Logo Fuel Ventures" />,
  <img src="/images/logos/bonline.svg" alt="Logo bOnline" />,
  <img src="/images/logos/hownow.svg" alt="Logo Hownow" />,
  <img src="/images/logos/expocart.svg" alt="Logo Expocart" />,
  <img src="/images/logos/eazio.svg" alt="Logo Eazio" />,
  <img src="/images/logos/walmer.svg" alt="Logo Walmer" />,
];

const SocialProofGrid = () => {
  const numLogos = _logos.length;
  const numCols = 3;
  const numRows = Math.ceil(numLogos / numCols);
  const lastRowNumLogos = numLogos % numCols;

  return (
    <div className="grid grid-cols-2 gap-8 mx-auto md:grid-cols-3 lg:grid-cols-7 max-w-96 md:max-w-2xl lg:max-w-3xl xl:max-w-none">
      {_logos.map((logo, index) => (
        <div
          key={index}
          className={`flex items-center justify-center ${
            index >= numCols * (numRows - 1) && lastRowNumLogos === 1
              ? "col-span-2 md:col-span-3 lg:col-span-1"
              : ""
          }`}
        >
          <div className="w-24 md:w-36 lg:w-48">{logo}</div>
        </div>
      ))}
    </div>
  );
};

export default SocialProofGrid;

// const SocialProof = () => {
//   return (
//     <div className="flex flex-wrap items-center justify-center mx-auto space-x-8 space-y-8 lg:space-x-10 max-w-96 md:max-w-2xl lg:max-w-3xl xl:max-w-none">
//       {_logos.map((logo, index) => (
//         // NOTE: No idea why the outfund.svg is different to the others, it does not get vertically centered. Might not be
//         // an issue with the SVG itself, but with it being the first in this flex layout.
//         // Anyways the `pt-8` class for the first element is a workaround for this.
//         <div
//           key={index}
//           className={twMerge(
//             "flex items-center justify-center w-24 md:w-36",
//             index === 1000 ? "pt-8 ml-4 w-28" : "",
//           )}
//         >
//           {logo}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default SocialProof;
