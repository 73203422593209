const HeadlineDecoratorDarker = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 465 27"
    {...props}
  >
    <path
      fill="#C750ED"
      d="M460.982 26.685c-1.757.505-8.259.264-25.513-.955-50.296-3.55-99.273-6.1-143.702-7.49-44.37-1.39-77.772-1.557-134.744-.684-30.764.474-64.756 1.949-102.579 4.449-11.615.77-26.678 1.628-31.45 1.789-5.771.2-7.652-.01-9.524-1.052-2.984-1.663-2.18-4.105 1.714-5.22 2.117-.602 4.4-.812 16.285-1.492 5.566-.316 15.098-.912 21.173-1.319 24.119-1.617 48.099-2.85 70.833-3.653 5.304-.187 11.053-.397 12.763-.469 2.033-.083-8.958-.22-31.739-.397-48.742-.376-73.17-.274-88.408.37-5.194.217-9.319.313-10.152.24-2.171-.199-4.05-.957-5.012-2.03-1.01-1.13-1.025-2.017-.046-3.144C2.71 3.533 5.307 3.17 21.169 2.788a992.239 992.239 0 0 0 14.474-.46c10.759-.455 21.754-.776 41.4-1.207C112.504.34 113.954.322 153.61.139c60.926-.29 138.325.334 176.275 1.417 4.796.14 15.292.429 23.331.647a2298.25 2298.25 0 0 1 63.309 2.575c9.61.526 13.706.667 23.273.81 9.605.143 11.766.22 12.518.44 1.702.496 2.661 1.772 2.131 2.829-.374.732-2.247 1.703-3.783 1.961-1.928.322-23.463-.093-37.303-.72-11.469-.52-17.011-.727-34.375-1.294-12.396-.405-62.224-1.155-73.74-1.11-5.992.02-10.545.067-10.117.096.424.031 10.995.461 23.486.95 27.505 1.087 32.251 1.31 49.767 2.347 31.928 1.887 33.652 2.024 65.251 5.201 19.053 1.914 24.403 2.652 27.674 3.818 4.835 1.722 4.665 5.138-.33 6.581l.004-.002Z"
    />
  </svg>
);
export default HeadlineDecoratorDarker;
