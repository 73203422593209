const HeadlineDecorator = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    // width={props.width ?? "90%"}
    // height="auto"
    viewBox="0 0 369 15"
    {...props}
  >
    <path
      fill="#E9BAF8"
      d="M3.145 14.41c1.393.245 6.554.107 20.252-.544 39.929-1.9 78.808-3.3 114.074-4.114 35.22-.814 61.731-.99 106.946-.72 24.416.147 51.39.78 81.402 1.91 9.217.348 21.169.73 24.956.796 4.58.083 6.073-.026 7.563-.547 2.372-.831 1.741-2.037-1.346-2.578-1.679-.292-3.49-.39-12.921-.692-4.417-.14-11.98-.409-16.801-.593a2866.211 2866.211 0 0 0-56.208-1.607 4145.48 4145.48 0 0 1-10.129-.195c-1.613-.036 7.111-.135 25.192-.287 38.687-.324 58.075-.343 70.166-.068 4.122.093 7.396.13 8.057.091 1.724-.105 3.218-.485 3.984-1.019.805-.563.819-1.001.046-1.556-1.446-1.031-3.507-1.204-16.095-1.347-3.535-.041-8.701-.124-11.486-.188-8.538-.194-17.263-.322-32.855-.48-28.143-.284-29.294-.29-60.768-.268-48.355.03-109.786.557-139.91 1.2-3.807.083-12.138.256-18.52.387-17.132.35-33.834.834-50.254 1.453-7.628.288-10.88.37-18.473.467-7.624.098-9.34.142-9.936.253-1.352.25-2.118.885-1.7 1.406.295.361 1.779.836 2.998.96 1.528.154 18.622-.113 29.608-.462 9.104-.29 13.503-.408 27.286-.738 9.84-.235 49.39-.748 58.53-.758 4.755-.007 8.369.003 8.029.019-.337.016-8.728.259-18.643.537-21.833.615-25.6.739-39.506 1.302C51.337 7.454 49.968 7.527 24.88 9.188 9.752 10.19 5.505 10.57 2.905 11.156c-3.843.866-3.717 2.555.243 3.255h-.003Z"
    />
  </svg>
);
export default HeadlineDecorator;
