/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/HW4NyzaJjDV
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { WithUnderlineDecorator } from "./WithUnderlineDecorator";
import JoinWaitlistButton from "./join-waitlist-button";
import JoinWaitlistForm from "./join-waitlist-form";
import SocialProof from "./social-proof";

const handleScrollToSection = () => {
  const section = document.querySelector("#dear-reader");

  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

export function Homepage() {
  return (
    <div className="bg-[#F9F7F6] text-gray-700 font-sans leading-normal tracking-normal">
      <header className="container text-center md:mx-auto">
        <div className="space-y-10">
          <h1 className="mt-4 text-2xl font-bold leading-tight md:text-5xl md:my-8">
            Verify personalisation,
            <br />
            <WithUnderlineDecorator variant="light">
              boost replies.
            </WithUnderlineDecorator>
          </h1>

          <p className="text-lg _md:text-xl text-gray-700 max-w-[40rem] lg:max-w-[45rem] md:mx-auto">
            Our unique verification technology guarantees prospects that your
            tailored email is genuinely personalised, ensuring you stand out in
            crowded inboxes.
          </p>

          <p className="font-bold text-lg _md:text-xl max-w-[40rem] lg:max-w-[44rem] md:mx-auto">
            We're in beta test mode right now with a few companies from our
            first waitlist. Be a part of the next wave by joining the queue.
            We'll be in touch.
          </p>
        </div>

        <div className="flex justify-center mt-10 md:my-12">
          {/* <JoinWaitlistForm /> */}
          <JoinWaitlistButton variant="default" />
        </div>

        <ChevronDownIcon
          onClick={handleScrollToSection}
          className="w-12 h-12 mx-auto mt-0 cursor-pointer md:hidden text-mauve-600 opacity-70"
        />

        <div className="my-4 md:my-10">
          <SocialProof />
        </div>

        <ChevronDownIcon
          onClick={handleScrollToSection}
          className="hidden w-12 h-12 mx-auto cursor-pointer md:block md:mb-4 text-mauve-600 opacity-70"
        />
      </header>

      <section
        id="dear-reader"
        className="p-8 pt-12 mt-10 bg-mauve-500 md:mt-0 lg:p-12"
      >
        {/* <div className="flex-row items-center justify-center mt-12 p-8 space-y-8 max-w-[600px]"> */}
        <div className="max-w-[600px] mx-auto space-y-8">
          <div className="flex justify-center md:justify-start">
            <h2 className="inline-block text-4xl font-bold">
              <WithUnderlineDecorator variant="dark" marginTop="0">
                Dear sender
              </WithUnderlineDecorator>
            </h2>
          </div>

          <div className="space-y-4 text-lg text-gray-700">
            <div>
              The HumanPass verification platform certifies that your cold email
              has been personally sent, by you, to your prospect.
            </div>
            <div>
              With declining email open rates and dwindling responses, we
              recognise automation's role but have lived through the frustration
              of being seen as a nuisance and wanted to make a change.
            </div>
            <div>
              Combatting email fatigue, HumanPass proves to recipients
              {`
            that they're more than just a {{First_Name}} on a bulk e-blast; `}
              they're a valued prospect who deserves a personal approach.
            </div>
            <div>
              We all hate spending time carefully researching the perfect
              prospect & constructing a beautifully tailored email just to
              receive a brutal "unsubscribe" reply? Wouldn't you love to verify
              that you really do see a mutual benefit to having a conversation?
            </div>
            <div>
              At HumanPass, our tech certifies the personalisation & uniqueness
              of your message, authenticating that this isn't just another copy
              & paste, send-to-all-that-might-be-relevant- and-hope-for-the-best
              campaign; boosting open & reply rates and earning you a foot in
              the door.
            </div>
            <div>
              Join our waiting list now for a chance to stand out to your top
              priority targets above the sea of AI Bots & SDR armies. Unlock the
              response rates you've been aiming for.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function ChevronDownIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  );
}
