import HeadlineDecorator from "./design-elements/headline-decorator";
import HeadlineDecoratorDarker from "./design-elements/headline-decorator-darker";

export function WithUnderlineDecorator({
  children, marginTop = "-0.7rem", variant,
}: {
  children: React.ReactNode;
  marginTop?: string;
  variant?: "light" | "dark";
}) {
  const Decorator = variant === "light" ? HeadlineDecorator : HeadlineDecoratorDarker;

  return (
    <div className="inline-block">
      <span className="relative z-10">{children}</span>
      <div className="md:hidden z-0">
        <Decorator style={{ marginTop: "-0.3rem" }} />
      </div>

      <div className="hidden md:block z-0">
        <Decorator style={{ marginTop }} />
      </div>
    </div>
  );
}
